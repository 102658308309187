<template>
  <div class="loader" v-show="springLoader">
    <DoubleBounce></DoubleBounce>
  </div>
  <div v-show="!springLoader">
    <table
      cellspacing="0"
      border="0"
      cellpadding="0"
      width="100%"
      bgcolor="#f2f3f8"
      style="
        @import url(https://fonts.googleapis.com/css?family=Rubik:300,400,500,700|Open+Sans:300,400,600,700);
        font-family: 'Open Sans', sans-serif;
      "
    >
      <tr>
        <td>
          <table
            style="background-color: #f2f3f8; max-width: 670px; margin: 0 auto"
            width="100%"
            border="0"
            align="center"
            cellpadding="0"
            cellspacing="0"
          >
            <tr>
              <td style="height: 80px">&nbsp;</td>
            </tr>
            <tr>
              <td style="text-align: center">
                <a href="https://rakeshmandal.com" title="logo" target="_blank">
                  <img width="150" :src="logo" title="logo" alt="logo" />
                </a>
              </td>
            </tr>
            <tr>
              <td style="height: 20px">&nbsp;</td>
            </tr>
            <tr>
              <td>
                <table
                  width="95%"
                  border="0"
                  align="center"
                  cellpadding="0"
                  cellspacing="0"
                  style="
                    max-width: 670px;
                    background: #fff;
                    border-radius: 3px;
                    text-align: center;
                    -webkit-box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.06);
                    -moz-box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.06);
                    box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.06);
                  "
                >
                  <tr>
                    <td style="height: 40px">&nbsp;</td>
                  </tr>
                  <tr>
                    <td style="padding: 0 35px" v-if="invalidIdentity">
                      <img width="150" :src="remove" title="logo" alt="logo" />
                      <h1
                        style="
                          color: #1e1e2d;
                          font-weight: 900;
                          margin: 0;
                          font-size: 32px;
                          font-family: 'Rubik', sans-serif;
                        "
                      >
                        We were unable to confirm your identity.
                      </h1>
                      <br />
                      <h1
                        style="
                          color: #1e1e2d;
                          font-weight: 500;
                          margin: 0;
                          font-size: 25px;
                          font-family: 'Rubik', sans-serif;
                        "
                      >
                        Please try again later.
                      </h1>

                      <button
                        class="btn btnReset"
                        type="button"
                        @click="backToLogin"
                      >
                        Back to Login
                      </button>
                    </td>
                    <td style="padding: 0 35px" v-if="!invalidIdentity">
                      <h1
                        style="
                          color: #1e1e2d;
                          font-weight: 500;
                          margin: 0;
                          font-size: 32px;
                          font-family: 'Rubik', sans-serif;
                        "
                      >
                        You have requested to reset your password
                      </h1>
                      <div class="text-start mt-3 mb-3">
                        <form action="">
                          <div class="form-group mx-0 mt-3">
                            <label for="inputPassword">New Password</label>
                            <input
                              type="password"
                              :class="
                                !isLanguageEN
                                  ? `form-control border-1 AR-lang-password  ${
                                      errors.userPassword ? 'is-invalid' : ''
                                    }`
                                  : `form-control border-1  ${
                                      errors.userPassword ? 'is-invalid' : ''
                                    }`
                              "
                              id="inputPassword"
                              placeholder="Password"
                              name="New password"
                              required
                              v-model="userPassword"
                              @keyup="checkPasswordValidation($event)"
                              @blur="checkPasswordValidationBlurFnc($event)"
                            />
                            <div id="pswd_info">
                              <ul>
                                <li id="length" class="invalid">
                                  Be at least <strong>8 characters</strong>
                                </li>
                                <li id="number" class="invalid">
                                  At least <strong>one number</strong>
                                </li>
                                <li id="letter" class="invalid">
                                  <strong>one lowwercase letter</strong>
                                </li>
                                <li id="capital" class="invalid">
                                  <strong>one uppercase letter</strong>
                                </li>
                                <li id="symbol" class="invalid">
                                  <strong>Symbol !@#$%^&*</strong>
                                </li>
                              </ul>
                            </div>
                            <div class="invalid-feedback">
                              Password is required.
                            </div>
                          </div>

                          <div class="form-group mx-0 mt-3">
                            <label for="inputPassword">Confirm Password</label>
                            <input
                              type="password"
                              class="form-control"
                              id="inputPassword"
                              placeholder="Password"
                              name="Confirm password"
                              v-model="userConfPassword"
                              required
                            />
                            <div class="invalid-feedback">
                              Password is required.
                            </div>
                          </div>
                        </form>
                      </div>
                      <p
                        style="
                          color: #455056;
                          font-size: 15px;
                          line-height: 24px;
                          margin: 0;
                        "
                      >
                        We cannot simply send you your old password. A unique
                        link to reset your password has been generated for you.
                        To reset your password, re enter your new password and
                        click reset button
                      </p>
                      <button
                        class="btn btnReset"
                        type="button"
                        @click="resetPassword"
                      >
                        Reset Password
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td style="height: 40px">&nbsp;</td>
                  </tr>
                </table>
              </td>
            </tr>

            <tr>
              <td style="height: 20px">&nbsp;</td>
            </tr>
            <tr>
              <td style="height: 80px">&nbsp;</td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import axios from "axios";
import logo from "../assets/MyPorterLogo/logo.png";
import remove from "../assets/img/remove.png";
import VueSweetalert2 from "sweetalert2";
import { DoubleBounce } from "vue-loading-spinner";
import AuthenticationService from "../services/UserService";
export default {
  components: { DoubleBounce },
  data() {
    return {
      logo,
      remove,
      userPassword: "",
      userConfPassword: "",
      key: "",
      email: "",
      springLoader: true,
      invalidIdentity: false,
      isLanguageEN: true,
      errors: {},
    };
  },
  mounted() {
    const langType = localStorage.getItem("lang");
    if (langType === "EN") {
      this.isLanguageEN = true;
    } else if (langType === "AR") {
      this.isLanguageEN = false;
    }
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    this.key = params.Key;
    this.email = params.email;

    this.ValidateUser(this.$route.params.email, this.$route.params.otp);
    $("#pswd_info").hide();
  },
  methods: {
    ValidateUser(email, otp) {
      this.email = email;
      AuthenticationService.validReciveCode(email, otp, true)
        .then((responseData) => {
          if (responseData.data.isValid) {
            this.springLoader = false;
            this.invalidIdentity = false;
          } else {
            this.springLoader = false;
            this.invalidIdentity = true;
          }
        })
        .catch((error) => {
          this.errorMessage = error.response.data.message;
          if (
            error.response.data.errors[0].message == "This user does not exist."
          ) {
            this.springLoader = false;
            this.invalidIdentity = true;
          }
        });
    },
    backToLogin() {
      window.location.href = "/login";
    },
    checkPasswordValidation(event) {
      let validCount = 0;
      this.errors.userPassword = "";
      this.errors.userConfPassword = "";

      $("#pswd_info").show();

      //validate the length
      if (event.target.value.length < 8) {
        $("#length").removeClass("valid").addClass("invalid");
        validCount--;
      } else {
        $("#length").removeClass("invalid").addClass("valid");
        validCount++;
      }

      //validate letter
      if (event.target.value.match(/[a-z]/)) {
        $("#letter").removeClass("invalid").addClass("valid");
        validCount++;
      } else {
        $("#letter").removeClass("valid").addClass("invalid");
        validCount--;
      }

      //validate capital letter
      if (event.target.value.match(/[A-Z]/)) {
        $("#capital").removeClass("invalid").addClass("valid");
        validCount++;
      } else {
        $("#capital").removeClass("valid").addClass("invalid");
        validCount--;
      }

      //validate number
      if (event.target.value.match(/\d/)) {
        $("#number").removeClass("invalid").addClass("valid");
        validCount++;
      } else {
        $("#number").removeClass("valid").addClass("invalid");
        validCount--;
      }

      //validate symbol
      if (event.target.value.match(/[*@!#%&()^~{}]+/)) {
        $("#symbol").removeClass("invalid").addClass("valid");
        validCount++;
      } else {
        $("#symbol").removeClass("valid").addClass("invalid");
        validCount--;
      }

      if (validCount === 5) {
        this.correctPasswordPolicy = true;
      }
    },

    checkPasswordValidationBlurFnc(event) {
      // document.getElementById('con_pswd_info').style.display = 'block';
      $("#pswd_info").hide();
    },

    resetPassword() {
      if (this.userPassword !== this.userConfPassword) {
        VueSweetalert2.fire({
          toast: false,
          position: "center",
          showConfirmButton: false,
          timer: 3000,
          title: "Something went wrong! password not match",
          icon: "error",
        });
      } else {
        AuthenticationService.resetPassword(this.email, this.userPassword)
          .then((responseData) => {
            if (responseData) {
              VueSweetalert2.fire({
                toast: false,
                position: "center",
                showConfirmButton: false,
                timer: 3000,
                title: "Password has been reset successfully.",
                icon: "success",
              });
              setTimeout(() => {
                window.location.href = "/login";
              }, 500);
            }
          })
          .catch((error) => {
            VueSweetalert2.fire({
              toast: false,
              position: "center",
              showConfirmButton: false,
              timer: 3000,
              title: error.response.data.errors[0].message,
              icon: "error",
            });
          });
        // axios
        //   .post(
        //     "http://login.emerge.lk:9090/MyPorterAPI/api/Auth/ValidForgetPassword",
        //     data
        //   )
        //   .then((res) => {
        //     if (res.data) {
        //       axios
        //         .post(
        //           "http://login.emerge.lk:9090/MyPorterAPI/api/Auth/ForgetPassword",
        //           data
        //         )
        //         .then((res) => {
        //           if (res.data) {
        //             VueSweetalert2.fire({
        //               toast: false,
        //               position: "center",
        //               showConfirmButton: false,
        //               timer: 3000,
        //               title: "Password has been reset successfully",
        //               icon: "success",
        //             });
        //             setTimeout(() => {
        //               window.location.href = "http://mpuat.emerge.lk/login";
        //             }, 500);
        //           } else {
        //             VueSweetalert2.fire({
        //               toast: false,
        //               position: "center",
        //               showConfirmButton: false,
        //               timer: 3000,
        //               title: "Something went wrong! Password reset failed",
        //               icon: "error",
        //             });
        //           }
        //         });
        //     }
        //   });
      }
    },
  },
};
</script>

<style>
.btnReset {
  background: #006203;
  text-decoration: none !important;
  font-weight: 500;
  margin-top: 35px;
  color: #fff;
  /* text-transform: uppercase; */
  font-size: 14px;
  padding: 10px 24px;
  display: inline-block;
  border-radius: 50px;
}
.btnReset:hover {
  color: #fff;
  background: #1e8722;
}
.loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

#pswd_info {
  /* bottom: -115px\9; IE Specific */
  right: -470px;
  width: 250px;
  padding: 15px;
  background: #fefefe;
  font-size: 0.875em;
  border-radius: 5px;
  box-shadow: 0 1px 3px #ccc;
  border: 1px solid #ddd;
  border-radius: 8px;
}
.AR-lang-pswd_info {
  position: relative;
  top: -35px;
  bottom: -115px\9; /* IE Specific */
  right: 311px !important;
  width: 250px;
  padding: 15px;
  background: #fefefe;
  font-size: 0.875em;
  border-radius: 5px;
  box-shadow: 0 1px 3px #ccc;
  border: 1px solid #ddd;
  border-radius: 8px;
}

#con_pswd_info {
  position: relative;
  top: -35px;
  bottom: -115px\9; /* IE Specific */
  right: -470px;
  width: 250px;
  padding: 15px;
  background: #fefefe;
  font-size: 0.875em;
  border-radius: 5px;
  box-shadow: 0 1px 3px #ccc;
  border: 1px solid #ddd;
  border-radius: 8px;
}

#pswd_info::before {
  content: "\25B2";
  position: relative;
  top: -32px;
  left: 5%;
  font-size: 14px;
  line-height: 14px;
  color: #ddd;
  text-shadow: none;
  display: block;
}
#con_pswd_info::before {
  content: "\25B2";
  position: absolute;
  top: -12px;
  left: 5%;
  font-size: 14px;
  line-height: 14px;
  color: #ddd;
  text-shadow: none;
  display: block;
}
.invalid {
  background: url(../assets/Icons/invalid.png) no-repeat 0 50%;
  padding-left: 22px;
  line-height: 24px;
  color: #ec3f41;
}
.valid {
  background: url(../assets/Icons/valid.png) no-repeat 0 50%;
  padding-left: 22px;
  line-height: 24px;
  color: #3a7d34;
}

.AR-lang-password {
  padding-right: 26px;
}
.AR-lang-confPassword {
  padding-right: 26px;
}
.hidePassword {
  position: relative;
  bottom: 30px;
  left: 10px;
}
.AR-lang-hidePassword {
  position: relative;
  bottom: 30px;
  right: 4px;
}

@media screen and (max-width: 1024px) {
  #con_pswd_info {
    left: 50px;
  }
}

@media screen and (max-width: 600px) {
  #con_pswd_info {
    left: 50px;
  }

  /* #pswd_info {
    left: 50px;
  } */
}

@media screen and (max-width: 768px) {
  #con_pswd_info {
    left: 250px;
  }

  #pswd_info {
    left: 250px;
  }
  .AR-lang-pswd_info {
    right: 182px !important;
  }
}

@media screen and (max-width: 425px) {
  .AR-lang-pswd_info {
    right: 182px !important;
    top: -16px;

    right: -407px;
  }
}
</style>
